import { getMeasureProxies } from "../selectors/measures";
import MeasuresDraftStore from "../MeasuresDraftStore";
import moment from "moment-timezone";
import MeasuresScoreStore from "../MeasuresScoreStore";
import NPIStore from "../NPIStore";

// {
//     id: string
// }
function MeasureSetProxy(id) {
    const _this = this;
    _this.id = id;

    _this.title = () => {
        const titleMap = {
            "quality": "Quality",
            "pi": "Promoting Interoperability",
            "ia": "Improvement Activities",
            "review": "Review & Submit"
        };
        return titleMap[_this.id];
    };

    _this.getMeasures = (selectedOnly) => {
        const measureProxies = getMeasureProxies(id !== "review" ? id : undefined);
        if(id === "review" || selectedOnly) {
            return measureProxies.filter((mp) => mp.isSelected());
        }
        return measureProxies.filter(m => !m.isExclusion()); // remove exclusions;
    };

    _this.hasOutsideEligiblePopulation = (selectedOnly = true) => {
        return !!_this.getMeasures(selectedOnly).filter(mp => mp.hasOutsideEligiblePopulation()).length;
    };

    _this.getSelectedMeasuresCount = () => {
        return _this.getMeasures(true).length;
    };

    _this.getPickedMeasuresCount = () => {
        return _this.getMeasures().filter(mp => mp.isPicked()).length;
    };

    _this.getScoreData = () => {
        if(id === "review") {
            return (MeasuresScoreStore.getTotalScoreData() || {});
        }
        return (MeasuresScoreStore.getMeasureSetScoreData(_this.id) || {});
    };

    _this.getErrorMessages = () => {
        return (MeasuresScoreStore.getErrorMessages(_this.id) || []);
    };

    _this.isIncluded = () => {
        return _this.isValid() && _this.getScoreData().included;
    };

    _this.isPicked = () => {
        return (_this.getScoreData().status || "SKIPPED") === "PICKED" ;
    };

    _this.getMaxScore = (unweighted = false) => {
        const scoreData = _this.getScoreData();
        return (unweighted ? scoreData.unweightedMaxScore : scoreData.maxScore) || 0;
    };

    _this.getScore = (unweighted = false) => {
        const scoreData = _this.getScoreData();
        let score = (unweighted ? scoreData.unweightedScore : scoreData.score) || 0;
        score = Math.round( ( score + Number.EPSILON ) * 100 ) / 100;
        return score;
    };

    _this.getWeight = () => {
        const scoreData = _this.getScoreData();
        return scoreData.weight || 0;
    };

    _this.getBonusScore = () => {
        const scoreData = _this.getScoreData();
        return scoreData.bonusScore || 0;
    };

    _this.getMessages = () => {
        const messages = [...(_this.getScoreData().messages || []), ...this.getErrorMessages() || []];

        if(_this.isPerformanceDateRequired() && !_this.isPerformanceDateValid()) {
            messages.push({
                type: "warning",
                title: `Please enter a valid performance start and end dates for ${_this.title()} to be included with the submission.`,
                details: "Valid performance date range are within the reporting performance year. Performance start date < Performance end date."
            });
        }
        if(_this.isCehrtIdRequired() && !_this.isCehrtIdValid()) {
            messages.push({
                type: "warning",
                title: `Please enter a valid CEHRT ID for ${_this.title()} to be included with the submission.`,
                details: "You will need to obtain a valid CEHRT ID. It must be a 15 digit alphanumeric value resembling this format XX15EXXXXXXXXXX."
            });
        }
        const order = {
            error: 0,
            warning: 1,
            info: 2,
            success: 3
        };
        messages.sort((a, b) => {
            return a.type === b.type ? 0 : order[a.type] > order[b.type] ? 1 : -1;
        });

        return messages;
    };

    _this.getMessageCounts = () => {
        const messageTypes = {
            error: 0,
            warning: 0,
            info: 0,
            success: 0
        };
        _this.getMessages().forEach(it => {
            messageTypes[it.type]++;
        });
        return Object.keys(messageTypes).map((k) => ({type: k, count: messageTypes[k]}));
    };

    _this.isPerformanceDateRequired = () => {
        return ["ia", "pi"].includes(id);
    };

    _this.isCehrtIdRequired = () => {
        return ["pi"].includes(id);
    };

    _this.getValue = () => {
        const value = MeasuresDraftStore.getMeasureSet(id);
        if(!value) {
            return {
                "id": null,
                "category": id,
                "state": "DRAFT",
                "lastEvent": "NONE",
                "performanceStart": null,
                "performanceEnd": null,
                "cehrtId": null
            };
        }
        return value;
    };

    _this.isPerformanceDateValid = () => {
        const value = _this.getValue();
        if(
            _this.isPerformanceDateRequired()
        ) {
            return isValidPerformaceDate( NPIStore.getYear().year, value.performanceStart, value.performanceEnd, false);
        }
        return true;
    };

    _this.isCehrtIdValid = () => {
        if(
            _this.isCehrtIdRequired()
        ) {
            return isValidCehrtId(_this.getValue().cehrtId, false);
        }
        return true;
    };

    _this.isValid = () => {
        return _this.isPerformanceDateValid() && _this.isCehrtIdValid();
    };
}

export const isValidCehrtId = (value, allowNull = false) => {
    if(allowNull && value == null) return true;
    if(value == null) return false;
    return !! value.match(/^[A-Za-z0-9]{2}15[EC][A-Za-z0-9]{10}$/);
};

export const isValidPerformaceDate = (submissionYear, startValue, endValue, allowNull = false) => {
    if(allowNull && startValue == null && endValue == null) {
        return true;
    }
    if(startValue == null || endValue == null) {
        return false;
    }
    startValue = moment(startValue);
    endValue = moment(endValue);
    if(!moment(startValue).isValid() || !moment(endValue).isValid()) {
        return false;
    }
    if(startValue > endValue) {
        return false;
    }
    const minDate = moment(submissionYear + "-01-01T00:00:00.000Z").utc();
    const maxDate = moment(submissionYear + "-12-31T23:59:59.999Z").utc();
    return (startValue >= minDate && startValue < maxDate) &&
        (endValue >= minDate && endValue < maxDate);
};

export default MeasureSetProxy;

const measureSetProxyCache = {};
export const getOrCreateMeasureSetProxy = (id, label) => {
    if(!measureSetProxyCache[id]) {
        // only need to create this once because no data is stored in a
        // MeasureSetProxy other than the id.
        measureSetProxyCache[id] = new MeasureSetProxy(id, label);
    }
    return measureSetProxyCache[id];
};
