import {ReduceStore} from 'flux/utils';
import Dispatcher from '../Dispatcher';
import {
    asyncActionWrapper,
    asyncReducerStatus,
    buildAsyncReducer, 
    isError,
    isLoaded,
    isLoading
} from './utils/utils/asyncReducer';
import { asyncInitialState, asyncStatus, asyncActionStatus } from "./utils/utils/asyncReducer";
import MeasuresDraftActions from './MeasuresDraftActions';
import api from "../../lib/api";
import { buildUrl } from "../../../routes";
import UserActions from "../UserActions";
import LocationActions from "../LocationActions";
import history from "../../lib/history";
import LocationStore from "../LocationStore";
import {notificationsState} from "../../views/components/Notifications";

const initialState = {
    ...asyncInitialState,
    paramHash: "",
    data: {
        measurementSets: [],
        qualityMeasurements: []
    }
};
const measuresDraftAsyncReducer = buildAsyncReducer(MeasuresDraftActions, initialState);

const requestDraftData = (params) => {
    const {year, orgKey, implKey, npiId} = params;
    return api.loadSubmitDraftData(year, orgKey, implKey, npiId)
        .then((res) => {
            const data = res.data;
            Dispatcher.dispatch({ type: MeasuresDraftActions.LOADED, data: data });
            return Promise.resolve(res);
        })
        .catch((err) => { // error!
            Dispatcher.dispatch({ type: MeasuresDraftActions.ERROR });
            const url = buildUrl("MIPS", {year: year, orgKey: orgKey, implKey: implKey});
            history.push(url);
            notificationsState.onPush({
                type: "error",
                message: "Failed to load the draft!",
                stacked: true,
                size: "medium",
                horizontalPosition: "right",
                verticalPosition: "top"
            });
            return Promise.reject(err);
        });
};

const saveDraftMeasureSetData = (params, measurementSet) => {
    const {year, orgKey, implKey, npiId} = params;
    return api.saveSubmitDraftData(year, orgKey, implKey, npiId, measurementSet)
        .then((res) => {
            const data = res.data;
            Dispatcher.dispatch({ type: MeasuresDraftActions.SAVED, data: data });
            return Promise.resolve(res);
        })
        .catch((err) => { // error!
            Dispatcher.dispatch({ type: MeasuresDraftActions.ERROR });
            notificationsState.onPush({
                type: "error",
                message: "Failed to save the draft!",
                stacked: true,
                size: "medium",
                horizontalPosition: "right",
                verticalPosition: "top"
            });
            return Promise.reject(err);
        });
};

const submitDraftToMips = (params, action) => {
    const {year, orgKey, implKey, npiId, type} = params;
    // replace navigation to receipt page.
    const url = buildUrl("RECEIPT", {year: year, orgKey: orgKey, implKey: implKey, npiId: npiId, receiptId: "", type});
    history.replace(url);

    api.submitSubmitToMips(year, orgKey, implKey, npiId)
        .then((res) => {
            const data = res.data;
            Dispatcher.dispatch(asyncActionWrapper({ ...action, data: data }, asyncStatus.LOADED ) );
            return Promise.resolve(res);
        })
        .catch((err) => { // error!
            Dispatcher.dispatch(asyncActionWrapper({ ...action}, MeasuresDraftActions.ERROR ) );
            notificationsState.onPush({
                type: "error",
                message: "Failed to submit the draft!",
                stacked: true,
                size: "medium",
                horizontalPosition: "right",
                verticalPosition: "top"
            });
            return Promise.reject(err);
        });
};

export const getMeasurementSet = (state, category) => {
    if(!state.data.measurementSets) {
       return null;
    }
    return state.data.measurementSets.find(it => it.category === category) || null;
};


export const getMeasureValue = (state, category, measureId) => {
    const measurementSet = getMeasurementSet(state, category);
    if(!measurementSet || !measurementSet.measureValues) {
        return null;
    }
    return measurementSet.measureValues.find(it => it.measure === measureId) || null;
};

export const getQualityMeasureData = (state, measureId) => {
    const measurements = state.data.qualityMeasurementComponents;
    if(!measurements) {
        return null;
    }
    return measurements.find(it => it.measure === measureId) || null;
};

const setMeasureValue = (state, action) => {
    let measurementSet = getMeasurementSet(state, action.category);
    if(!measurementSet) {
        measurementSet = {
            category: action.category,
            measureValues: []
        };
    }
    let found = false;
    const measureValues = measurementSet.measureValues.map(
            it => {
                if(it.measure === action.measureId) {
                    found = true;
                    if(action.value != null) {
                        return {...it, ...action.value};
                    }
                    else {
                        return null; // set to be removed.
                    }
                }
                if(action.exclusions != null && action.exclusions.includes(it.measure)) {
                    return null; // remove any previously selected exclusion
                }
                return it;
            }
        )
        .filter((it) => it != null); // remove all null values

    if(!found && action.value != null) {
        // create it
        measureValues.push({
            measure: action.measureId,
            metricType: action.metricType,
            ...action.value
        });
    }

    return setMeasureSetValue(state, {...action, value: {measureValues}});
};

const setMeasureSetValue = (state, action) => {
    let found = false;
    const measurementSets = state.data.measurementSets.map(
        it => {
            if(it.category === action.category) {
                found = true;
                return {
                    ...it,
                    ...action.value
                };
            }
            return it;
        }
    );
    if(!found) {
        measurementSets.push({
            category: action.category,
            measureValues: [],
            ...action.value
        });
    }
    return {
        ...state,
        data: {
            ...state.data,
            measurementSets: measurementSets
        }
    };
};

class MeasuresDraftStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
    }

    getInitialState() {
        return {...initialState};
    }

    reduce(state, action) {
        this.__dispatcher.waitFor([LocationStore.getDispatchToken()]);
        if (action.type === LocationActions.LOCATION_CHANGED && !["DRAFT"].includes(LocationStore.getRouteKey())) {
            return this.getInitialState(); // we only need this data for the above views.
        }
        switch(action.type) {
            case UserActions.LOADED:
            case LocationActions.LOCATION_CHANGED:
            case MeasuresDraftActions.LOAD:
                this.__dispatcher.waitFor([LocationStore.getDispatchToken()]);
                if(!["DRAFT", "RECEIPT"].includes(LocationStore.getRouteKey())) {
                    return state; // we only need this data for the above views.
                }
                requestDraftData(LocationStore.getParams());
                return {...state, status: asyncStatus.LOADING};
            case MeasuresDraftActions.ON_MEASURE_CHANGE:
                this.__dispatcher.waitFor([LocationStore.getDispatchToken()]);
                state = setMeasureValue(state, action);
                saveDraftMeasureSetData(
                    LocationStore.getParams(),
                    getMeasurementSet(
                        state,
                        action.category
                    )
                );
                return {...state, status: asyncStatus.LOADING};
            case MeasuresDraftActions.ON_MEASURE_SET_CHANGE:
                this.__dispatcher.waitFor([LocationStore.getDispatchToken()]);
                state = setMeasureSetValue(state, action);
                saveDraftMeasureSetData(
                    LocationStore.getParams(),
                    getMeasurementSet(
                        state,
                        action.category
                    )
                );
                return {...state, status: asyncStatus.LOADING};
            case MeasuresDraftActions.SAVED:
                return {...state, status: asyncStatus.LOADED};
            case MeasuresDraftActions.SUBMIT:
                if(asyncActionStatus(action) === asyncStatus.LOADING) {
                    this.__dispatcher.waitFor([LocationStore.getDispatchToken()]);
                    submitDraftToMips(
                        LocationStore.getParams(),
                        action
                    );
                }
                return {...state, status: asyncActionStatus(action)};
            default:
                return measuresDraftAsyncReducer(state, action);
        }
    }

    isLoading() {
        return isLoading(this.getStatus());
    }

    isError() {
        return isError(this.getStatus());
    }

    isLoaded() {
        return isLoaded(this.getStatus());
    }

    getStatus() {
        return asyncReducerStatus(this.getState());
    }

    getMeasureSet(category) {
        return getMeasurementSet(this.getState(), category);
    }

    getMeasureValue(category, measureId) {
        return getMeasureValue(this.getState(), category, measureId);
    }

    getQualityMeasureData(measureId) {
        return getQualityMeasureData(this.getState(), measureId);
    }

}

export default new MeasuresDraftStore();
