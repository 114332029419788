import MeasuresMetaStore from "../MeasuresMetaStore";
import {getOrCreateMeasureProxy} from "../utils/MeasureProxy";
import { titleCase } from "@armus/armus-dashboard";
import { formatMessage } from "@armus/armus-dashboard";

export const getMeasureProxies = (category) => {
    return MeasuresMetaStore.getAllMeasuresMeta(category)
        .map(m => getOrCreateMeasureProxy(m.measureId)); // convert to MeasureProxy
};

const categoryLabels = {
    pi: "Promoting Interoperability",
    ia: "Improvement Activities",
    quality: "Quality"
};

const filterKeys = [ // searchable keys to get values for
    "category",
    "metricType",
    "subcategory",
    "objective",
    "measureType",
    "primarySteward",
    "weight",
    "reportingCategory",
    "isRegistryMeasure",
    "isRiskAdjusted",
    "isInverse",
    "isHighPriority",
    "isRequired",
    "isBonus",
    "extraOptions"
];

const mergeDefaultFilterValues = (filters) => {
    // merges in-place
    filterKeys.forEach((key) => {
        const filter = filters[key] || {
            key: key,
            label: titleCase(key),
            values: {}
        };
        Object.keys(filter.values).forEach((k) => {
            filter.values[k] = {
                ...filter.values[k],
                count: filter.values[k].count === null ? null : 0
            };
        });
        filters[key] = filter;
    });
};

const cleanupFilterValues = (filters) => {
    // inplace cleanup
    filterKeys.forEach((filterKey) => {
        const valuesKeys = Object.keys(filters[filterKey].values);
        if(["extraOptions", "onlyPicked", "onlySelected", "search"].includes(filterKey)) {
            if(valuesKeys.length === 0) {
                delete filters[filterKey];
            }
            return;
        }
        if(valuesKeys.length <= 1) {
            // remove 1 value filters
            delete filters[filterKey];
        }
        else {
            valuesKeys.forEach((valueKey) => {
                if(filters[filterKey].values[valueKey].count === 0) {
                    // remove zero count filter values
                    delete filters[filterKey].values[valueKey];
                }
            });
        }
    });
};

export const buildMeasureFiltersObject = (measureSetProxy, filters, isReview) => {
    const measureProxies = measureSetProxy.getMeasures(isReview);
    let isUpdating = true;
    if(!filters) {
        // create filters
        isUpdating = false;
        filters = {};
    }
    else {
        filters = { ...filters };
    }
    mergeDefaultFilterValues(filters);

    measureProxies.forEach((m) => {
        filterKeys.forEach((filterKey) => {
            let valueKey = m.meta[filterKey];
            if(filterKey === "extraOptions" || valueKey === null) {
                valueKey = "other";
                return; // skip null for now.
            }
            if(valueKey === false || valueKey === true) {
                valueKey = valueKey ? "Yes" : "No";
            }
            let value = filters[filterKey].values[valueKey];
            if(!value) {
                value = {
                    key: valueKey,
                    label: titleCase(valueKey),
                    selected: false,
                    count: 0
                };
                if(filterKey === "category") {
                    value.label = categoryLabels[valueKey];
                }
                filters[filterKey].values[valueKey] = value;
            }
            value.count++;
        });
    });

    if(!isUpdating) {
        // add the search filter
        filters.search = {
            key: "search",
            label: formatMessage({id: "draft.filterBar.search", defaultMessage: "Search..."}) ,
            value: "",
            values: {
                measureId: {
                    key: "measureId",
                    label: "Measure ID",
                    selected: true,
                    count: 0
                },
                title: {
                    key: "title",
                    label: "Title",
                    selected: true,
                    count: 0
                },
                description: {
                    key: "description",
                    label: "Description",
                    selected: true,
                    count: 0
                }
            }
        };

        filters.onlySelected = {
            key: "onlySelected",
            label: formatMessage({id: "draft.filterBar.onlySelectedButton", defaultMessage: "Only Selected"}),
            value: false,
            values: {}
        };

        filters.onlyPicked = {
            key: "onlyPicked",
            label: formatMessage({id: "draft.filterBar.onlyPickedButton", defaultMessage: "Only Picked"}),
            value: false,
            values: {}
        };

        filters.extraOptions = {
            key: "extraOptions",
            label: "Extra Options",
            values: {}
        };
        // Now we want to always show this feature by default. so there is no reason to have it be a filter.
        // if(measureSetProxy.id === "quality") {
        //     filters.extraOptions.values.showOutsideEligiblePopulation = {
        //         key: "showOutsideEligiblePopulation",
        //         label: "Manage Outside Eligible Population",
        //         selected: measureSetProxy.hasOutsideEligiblePopulation(),
        //         count: null
        //     };
        // }
    }

    cleanupFilterValues(filters);
    return filters;
};

const preFilterMeasureProxies = (filters, measureProxies) => {
    return measureProxies.filter((mp) => {
        if(filters.onlySelected.value === true) {
            if(mp.getExclusions().filter(it => it.isSelected()).length) {
                // make sure the parent is returned so the picked child exclusion can be seen.
                return true;
            }
            if(!mp.isSelected()) return false;
        }
        if(filters.onlyPicked.value === true) {
            if(!mp.isPicked()) return false;
        }
        const meta = mp.meta;
        let include = false;
        let hasSelectedOne = false;
        let searchInclude = false;
        Object.keys(filters).forEach(filterKey => {
            const filter = filters[filterKey];
            Object.keys(filter.values).forEach((valueKey) => {
                const value = filter.values[valueKey];
                if(filterKey === "extraOptions" && value.selected) {
                    searchInclude = true;
                    //include = true;
                }
                else if(filterKey === "search") {
                    const v = meta[valueKey]; // title, description
                    // search value filter
                    if(filter.value !== "") {
                        const test = new RegExp(`${filter.value}`, "gi");
                        if(value.selected && v.match(test)) {
                            searchInclude = true;
                        }
                    }
                    else {
                        searchInclude = true; // no search include everything
                    }
                }
                else {
                    if (value.selected) {
                        const v = meta[filter.key];
                        // basic boolean key filter
                        let valueKey = value.key;
                        if (value.key === "Yes" || value.key === "No") {
                            valueKey = valueKey === "Yes";
                        }
                        if (valueKey === v) {
                            include = true;
                        }
                        hasSelectedOne = true;
                    }
                }
            });
        });
        return hasSelectedOne ? (include && searchInclude) : searchInclude;
    });
};

export const filterMeasureProxies = (filters, measureProxies) => {
    const filteredMeasureProxies = preFilterMeasureProxies(filters, measureProxies);
    // MS-454 - sorting alphanumerically by ID only now.
    // now sort required to the top
    // filteredMeasureProxies.sort((a, b) => {
    //     if(a.meta.isRequired && !b.meta.isRequired) {
    //         return -1;
    //     }
    //     if((a.meta.isRequired && b.meta.isRequired)
    //         || (!a.meta.isRequired && !b.meta.isRequired)) {
    //         return 0;
    //     }
    //     return 1;
    // });
    return filteredMeasureProxies;
};

export const getAppliedFiltersCount = (filters) => {
    let count = 0;
    Object.keys(filters).forEach(filterKey => {
        const filter = filters[filterKey];
        if([
            "search",
            "onlySelected",
            "onlyPicked",
            "category"
        ].includes(filterKey)) {
            // skip
            return;
        }
        Object.keys(filter.values).forEach((valueKey) => {
            const value = filter.values[valueKey];
            if(value.selected) {
                count++;
            }
        });
    });
    return count;
};
