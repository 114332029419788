import {ReduceStore} from 'flux/utils';
import Dispatcher from '../Dispatcher';
import {
    asyncReducerStatus,
    buildAsyncReducer,
    isError,
    isLoaded,
    isLoading
} from './utils/utils/asyncReducer';
import { asyncInitialState, asyncStatus } from "./utils/utils/asyncReducer";
import MeasuresScoreActions from './MeasuresScoreActions';
import MeasuresDraftActions from './MeasuresDraftActions';
import api from "../../lib/api";
import { titleCase } from "@armus/armus-dashboard";
import jsonPath from "../../lib/jsonPath";
import LocationActions from "../LocationActions";
import LocationStore from "../LocationStore";
import PerformanceYearStore from "../PerformanceYearStore";
import {notificationsState} from "../../views/components/Notifications";

export const getTotalScoreData = (state) => {
    const {score, maxScore, messages, bonusScore} = state.data;
    return {
        score,
        maxScore,
        messages,
        bonusScore
    };
};

export const getMeasureSetScoreData = (state, category) => {
    if(!state.data.measurementSets) {
        return null;
    }
    return state.data.measurementSets.find(it => it.category === category) || null;
};

export const getMeasureScoreData = (state, category, measureId) => {
    const measureSetScoreData = getMeasureSetScoreData(state, category);
    if(!measureSetScoreData) {
        return null;
    }
    return measureSetScoreData.measures.find(it => it.measureId === measureId) || null;
};

const buildMessage = (id, path, title, details, severity, isDataDef = false) => {
    const allText = `${title} ${details}`;
    if(
        allText.indexOf("is complete") !== -1
        || allText.indexOf("Bonus Received") !== -1
        || ["picked", "complete"].includes(id)
    ) {
        severity = "success";
    }
    if(allText.indexOf("is incomplete") !== -1) {
        severity = "warning";
    }
    return {
        id,
        path,
        title: !!details ? details : title,
        //details,
        type: severity,
        isDataDef
    };
};

const dataDefinitionKeyMapping = {
    // put any message keys that need to map to metadata keys here.
    e2eBonusScore: "endToEndBonus",
    measurementClass: "measureClass",
    picked: "processingStatus"
};

const processMessagePath = (data, path, severity = "info") => {
    const messageThing = jsonPath(data, path);
    if(messageThing == null) return [];
    if(Array.isArray(messageThing)) {
        return messageThing.map(it => {
            if(typeof it === "string") {
                return buildMessage(it, path, titleCase(it), null, severity);
            }
            return buildMessage(it.name, path, titleCase(it.detail), null, severity);
        });
    }
    // possible data def
    const parentPath = path.split("/");
    parentPath.pop();
    const parentThing = (jsonPath(data, parentPath.join("/")) || {});
    return Object.keys(messageThing).map(key => {
        const mappedDataKey = !!dataDefinitionKeyMapping[key] ? dataDefinitionKeyMapping[key] : key;
        const isDataDef = parentThing[mappedDataKey] !== undefined;
        return buildMessage(mappedDataKey, path, titleCase(mappedDataKey), titleCase(messageThing[key]), severity, isDataDef);
    });
};

//dedupe and separate data definitions
const splitMessagesAndDefinitions = (allMessages) => {
    const seen = {};
    const definitions = {};
    const messages = allMessages.filter((it) => {
        if(seen[it.title]) {
            return false;
        }
        seen[it.title] = true;
        if(it.isDataDef) {
            definitions[it.id] = it;
            return false;
        }
        return true;
    });
    return {
        messages,
        definitions
    };
};


export const processScoreData = (rawData) => {
    const data = {
        score: jsonPath(rawData, "extractedScore/overallScore") || 0,
        maxScore: jsonPath(rawData, "extractedScore/overallMaxScore") || 0,
        bonusScore: jsonPath(rawData, "extractedScore/bonusScore") || 0,
        messages: [
            ...processMessagePath(rawData, "rawResponse/data/score/warnings", "warning"),
            ...processMessagePath(rawData, "rawResponse/data/score/errors", "error")
        ],
        measurementSets: [
            {
                category: "quality",
                status: null,
                score: 0,
                maxScore: 0,
                unweightedScore: 0,
                unweightedMaxScore: 0,
                bonusScore: 0,
                measures: [],
                messages: [],
                definitions: {},
                included: false
            },
            {
                category: "pi",
                status: null,
                score: 0,
                maxScore: 0,
                unweightedScore: 0,
                unweightedMaxScore: 0,
                bonusScore: 0,
                measures: [],
                messages: [],
                definitions: {},
                included: false
            },
            {
                category: "ia",
                status: null,
                score: 0,
                maxScore: 0,
                unweightedScore: 0,
                unweightedMaxScore: 0,
                bonusScore: 0,
                measures: [],
                messages: [],
                definitions: {},
                included: false
            }
        ]
    };

    data.measurementSets.forEach((measureSet) => {
        const measureSetRoot = jsonPath(rawData, `rawResponse/data/mips/parts[@name="${measureSet.category}"]`);
        if(!!measureSetRoot) {
            measureSet.included = true;
            measureSet.score = jsonPath(rawData, `extractedScore/${measureSet.category}Score`) || 0;
            measureSet.maxScore =        jsonPath(rawData, `extractedScore/${measureSet.category}MaxContribution`) || 0;
            measureSet.unweightedScore = jsonPath(rawData, `extractedScore/${measureSet.category}MaxContribution`) || 0;
            measureSet.weight = jsonPath(rawData, `extractedScore/${measureSet.category}Weight`) || 0;

            measureSet.status =  jsonPath(measureSetRoot, "original/parts[0]/metadata/processingStatus");
            // Measure Set Messages
            const {messages, definitions} = splitMessagesAndDefinitions([
                // Category Level Feedback - Only available for PI and Quality
                ...processMessagePath(measureSetRoot, "original/warnings", "warning"),
                ...processMessagePath(measureSetRoot, "original/parts[0]/warnings", "warning"),
                // Category Level Warnings
                ...processMessagePath(measureSetRoot, "original/parts[0]/metadata/messages", "info"),
                //Category Level Feedback
                ...processMessagePath(rawData, `rawResponse/score/parts[@name="feedback-${measureSet.category}"]/parts`, "info") // [*]details
            ]);
            measureSet.messages = messages;
            measureSet.definitions = definitions;



            // Measure Set Measures
            const measureSetMeasures = jsonPath(measureSetRoot, "original/parts[0]/parts");
            if(!!measureSetMeasures) {
                measureSet.measures = measureSetMeasures.map((m) => {
                    const {messages, definitions} = splitMessagesAndDefinitions(
                        processMessagePath(m, "metadata/messages", "info")
                    );
                    return {
                        // Individual Measure ID
                        measureId: m.name,
                        // Individual Measure Score - always unweighted
                        score: jsonPath(m, "value") || 0,
                        bonusScore: 0,
                        // Individual Measure Processing Status
                        status: jsonPath(m, "metadata/processingStatus"),
                        // Individual Measure Warnings (only available on IA and Quality)
                        messages,
                        definitions,
                        // Quality Measure Score Data
                        percentiles: jsonPath(m, "metadata/percentiles"),
                        decile: jsonPath(m, "metadata/decile"),
                        decileScore: jsonPath(m, "metadata/decileScore")
                    };
                });
            }
        }
        return measureSet;
    });
    return data;
};

const initialState = {
    ...asyncInitialState,
    paramHash: "",
    data: processScoreData({}) // build initial data structure
};
const measuresScoreAsyncReducer = buildAsyncReducer(MeasuresScoreActions, initialState);

const requestScoreData = (params) => {
    const {year, orgKey, implKey, npiId} = params;
    return api.loadSubmitScoreData(year, orgKey, implKey, npiId)
        .then((res) => {
            const data = processScoreData(res.data);
            Dispatcher.dispatch({ type: MeasuresScoreActions.LOADED, data: data });
            return Promise.resolve(res);
        })
        .catch((err) => { // error!
            Dispatcher.dispatch({ type: MeasuresScoreActions.ERROR, error: err.response.data });
            notificationsState.onPush({
                type: "error",
                message: "Failed to load the scoring data!",
                stacked: true,
                size: "medium",
                horizontalPosition: "right",
                verticalPosition: "top"
            });
            return Promise.reject(err);
        });
};

class MeasuresScoreStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
    }

    getInitialState() {
        return {...initialState};
    }

    reduce(state, action) {
        switch(action.type) {
            case LocationActions.LOCATION_CHANGED:
                this.__dispatcher.waitFor([LocationStore.getDispatchToken()]);
                if(!["DRAFT"].includes(LocationStore.getRouteKey())) {
                    return this.getInitialState();
                }
                return state;
            case MeasuresDraftActions.LOADED:
            case MeasuresDraftActions.SAVED:
                this.__dispatcher.waitFor([LocationStore.getDispatchToken()]);

                const canScore = PerformanceYearStore.canScore();

                console.log("Scoring Enabled:", canScore);

                if(canScore) {
                    requestScoreData(LocationStore.getParams());
                    return {...state, status: asyncStatus.LOADING};
                }
                return state;
            default:
                return measuresScoreAsyncReducer(state, action);
        }
    }
    isLoading() {
        return isLoading(this.getStatus());
    }

    isError() {
        return isError(this.getStatus());
    }

    isLoaded() {
        return isLoaded(this.getStatus());
    }

    getStatus() {
        return asyncReducerStatus(this.getState());
    }

    getTotalScoreData() {
        return getTotalScoreData(this.getState());
    }

    getMeasureSetScoreData(category) {
        return getMeasureSetScoreData(this.getState(), category);
    }

    getMeasureScoreData(category, measureId) {
        return getMeasureScoreData(this.getState(), category, measureId);
    }

    getErrorMessages() {
        const err = this.getState().error;
        const hasErrors = err !== null && err.validationMessages !== undefined && err.validationMessages.length > 0;
        if(hasErrors) {
            return err.validationMessages.map(msg => ({
                type: "error",
                title: msg
            }));
        }
        return [];
    }
}

export default new MeasuresScoreStore();
