import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField/TextField";
import {Box} from "@mui/material";
import {titleCase} from "@armus/armus-dashboard";


const OutsideEligiblePopulation = ({measureProxy, onChange}) => {
    const defaultValue = measureProxy.getOutsideEligiblePopulation() || "";
    const [isError, setIsError] = React.useState(false);
    return (
        <Grid container sx={{mx: -2,mt:1,px: 2,pb:.5,borderRadius: 1.5, color: "orange", backgroundColor: isError ? "rgba(255,0,0, .1)" : "rgba(255,127,0, .1)"}}>
            <Grid item xs={6}>
                <Box fontWeight={"bold"} mt={1.75} color={isError ? "error.dark" : "warning.dark"}>{titleCase("outsideEligiblePopulation")}:</Box>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    key={measureProxy.getOutsideEligiblePopulation() || ""}
                    fullWidth={true}
                    margin="dense"
                    variant="outlined"
                    placeholder={"Number of Outside Records"}
                    label={""}
                    defaultValue={defaultValue}
                    error={isError}
                    onChange={(e) => setIsError(isNaN(e.target.value))}
                    onBlur={(e) => {
                        const value = isNaN(e.target.value) ? 0 : parseInt(e.target.value, 10);
                        onChange(
                            measureProxy.meta.category,
                            measureProxy.meta.measureId,
                            measureProxy.meta.metricType,
                            {
                                outsideEligiblePopulation: value
                            },
                            measureProxy.meta.exclusion
                        );
                    }}
                />
            </Grid>
            <Grid item xs={12} fontSize={"small"}>
                The number of eligible population (encounters) not captured in the registry. Leave blank if not applicable for this measure
            </Grid>
        </Grid>
    );
};

export default OutsideEligiblePopulation;
