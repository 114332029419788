import React from 'react';
import {Notifications as NotificationsView, useNotifications} from "@armus/armus-dashboard";

export const notificationsState = {
    queue: [],
    getQueue: () => {},
    setQueue: () => {},
    onPush: () => {},
    onPop: () => {},
    onHide: () => {},
    onRemove: () => {},
    onCleanup: () => {},
    onReset: () => {}
}; // accessor

const Notifications = (props) => {
    const {queue, getQueue, setQueue, onCleanup, onHide, onPush, onPop, onRemove, onReset} = useNotifications();
    notificationsState.queue = queue;
    notificationsState.getQueue = getQueue;
    notificationsState.setQueue = setQueue;
    notificationsState.onPush = onPush;
    notificationsState.onPop = onPop;
    notificationsState.onHide = onHide;
    notificationsState.onRemove = onRemove;
    notificationsState.onCleanup = onCleanup;
    notificationsState.onReset = onReset;
    return (
        <NotificationsView
            {...props}
            queue={queue}
            onCleanupNotifications={() => {
                onCleanup();
            }}
            onHideNotification={(index) => {
                const lastItem = queue.length > 0 ? queue.length-1 : 0; // remove oldest item on the queue.
                onHide(index !== undefined ? index : lastItem);
            }}
            onRemoveNotification={()=>{}}
        />
    );
};

Notifications.propTypes = {
    ...Notifications.propTypes
};

export default Notifications;
