import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import {formatMessage, FormattedMessage, Paper, Spinner, Main, AlertBar} from "@armus/armus-dashboard";
import Container from "../../../data/Container";
import NPIStore from "../../../data/submit/NPIStore";
import UserStore from "../../../data/UserStore";
import SubmissionReceiptStore from "../../../data/submit/SubmissionReceiptStore";
import {buildUrl} from "../../../../routes";
import ErrorPage from "../../ErrorPage";
import {homeBreadcrumb} from "../Home/Home";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {dispatchSubmit} from "../../../data/submit/MeasuresDraftActions";
import SummaryGauges from "../Draft/components/SummaryGauges";
import moment from "moment-timezone";
import config from "../../../../config";
import HasDRCF from "../../components/HasDRCF";

const useStyles = makeStyles((theme) => ({
    content: {
        height: "100%",
        overflow: "scroll"
    },
    paper: {
        display: "inline-block",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        padding: 0,
        minWidth: 600
    },
        tinNpiContainer: {
        color: "#888",
        fontSize: "80%"
    },
    title: {
        marginTop: 0
    }
}));

export const receiptBreadcrumb = (year, org = {}, impl = {}, npi = {}, type = "individual") => ({
    label: npi.tin ? formatMessage({
            id: "receipt." + type + ".breadcrumb",
            defaultMessage: "TIN: {tin} NPI: {npi} - Receipt"},
        npi
    ) : "...",
    url: buildUrl("DRAFT", {year, orgKey: org.key, implKey: impl.key, npiId: npi.id, type})
});

const getSelectedMeasuresCount = (receipt, category) => {
    if(category === "total") {
        let total = 0;
        ["quality", "pi", "ia"].forEach((category) => {
            total += (receipt.measurementSets.find((it) => it.category === category) || {measureValues: []}).measureValues.length;
        });
        return total;
    }
    return (receipt.measurementSets.find((it) => it.category === category) || {measureValues: []}).measureValues.length;
};

const buildSpinnerContent = (isSubmission) => {
    return isSubmission ? (
        <Box p={10} textAlign={"center"}>
            <LinearProgress />
            <br/>
            <FormattedMessage id={"receipt.processing.message"} defaultMessage={""} />
        </Box>
    ) : (
        <Box p={5}>
            <Spinner />
        </Box>
    );
};

const buildSuccessContent = (isSubmission, receipt) => {
    return (
        <React.Fragment>
            <FormattedMessage id={"receipt.success.message"} defaultMessage={"This submission was processed on {date}."} values={{date: moment(receipt.submittedAt).format(config.ui.dateTimeFormat)}} />
            {isSubmission && <AlertBar label={"Submission Success!"} severity={"success"} />}
            <SummaryGauges
                items={
                    [
                        {
                            category: "quality",
                            label: "Quality",
                            score: receipt.qualityScore || 0,
                            maxScore: receipt.qualityMaxContribution || 0,
                            weight: receipt.qualityWeight,
                            selectedMeasuresCount: getSelectedMeasuresCount(receipt, "quality")
                        },
                        {
                            category: "pi",
                            label: "Promoting Interoperability",
                            score: receipt.piScore || 0,
                            maxScore: receipt.piMaxContribution || 0,
                            weight: receipt.piWeight,
                            selectedMeasuresCount: getSelectedMeasuresCount(receipt, "pi")
                        },
                        {
                            category: "ia",
                            label: "Improvement Activities",
                            score: receipt.iaScore || 0,
                            maxScore: receipt.iaMaxContribution || 0,
                            weight: receipt.iaWeight,
                            selectedMeasuresCount: getSelectedMeasuresCount(receipt, "ia")
                        },
                        {
                            category: "total",
                            label: "Total",
                            score: receipt.overallScore || 0,
                            maxScore: receipt.overallMaxScore || 0,
                            selectedMeasuresCount: getSelectedMeasuresCount(receipt, "total"),
                            bonusScore: receipt.bonusScore || undefined
                        }
                    ].filter((it) => it.category === "total" || !!receipt.measurementSets.find((ms) => ms.category === it.category && ms.state === "SUCCESS"))
                }
                prefixScore={"Submission "}
            />
            <Typography variant={"body2"}>
                <FormattedMessage id={"receipt.success.disclaimer"} defaultMessage={"* This is an estimated score preview. It does not include the Cost Category."} />
            </Typography>
        </React.Fragment>
    );
};

const buildFailedContent = (isSubmission, receipt, onSubmit) => {
    return (
        <React.Fragment>
            <AlertBar label={<FormattedMessage id={"receipt.failed.alert"} defaultMessage={"Submission Failed!"} />} severity={"error"} />
            <FormattedMessage id={"receipt.failed.message"} defaultMessage={"There was a problem with this submission on {date}."}  values={{date: moment(receipt.submittedAt).format(config.ui.dateTimeFormat)}}  />
            <br/><br/>
            {isSubmission && (
                <div>
                    <FormattedMessage id={"receipt.failed.retryMessage"} defaultMessage={"Your submission could not be processed at this time. If the problem persists please contact support.<br/><br/>Would you like to try again?"} />
                    <br/><br/>
                    <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={onSubmit}
                    >
                        <FormattedMessage id={"receipt.failed.retryButton"} defaultMessage={"Retry Submission"}/>
                    </Button>
                </div>
            )}
        </React.Fragment>
    );
};

const Receipt = ({
    isValidURL,
    year,
    organization,
    implementation,
    npi,
    yearInfo,
    setView,
    isLoading,
    isError,
    isSubmission,
    receipt,
    onSubmit,
    submissionType
}) => {
    const classes = useStyles();

    if((!isLoading && !isValidURL) || !npi) {
        // the url is invalid show 404 page.
        return <ErrorPage statusCode={404} />;
    }

    const breadcrumb = receiptBreadcrumb(year, organization, implementation, npi, submissionType);
    let spinner = null;
    let body = null;
    if(isLoading || isError) {
        spinner = buildSpinnerContent(isSubmission);
    }
    else {
        let content = null;
        if(receipt.overallState === "SUCCESS") {
            content = buildSuccessContent(isSubmission, receipt);
        }
        else {
            content = buildFailedContent(isSubmission, receipt, onSubmit);
        }
        body = (
            <React.Fragment>
                <Box p={2}>
                    {submissionType === "individual" && <div className={classes.tinNpiContainer}>
                        TIN: <strong>{npi.tin}</strong> &nbsp;&nbsp;&nbsp; NPI: <strong>{npi.npi}</strong>
                    </div>}
                    <Typography className={classes.title} component="h1" variant="h4">
                        <FormattedMessage
                            id={"receipt." + submissionType + ".title"}
                            defaultMessage={"{name}"}
                            values={npi}
                        />
                    </Typography>
                    <FormattedMessage
                        id={"receipt.subTitle"}
                        defaultMessage={"Submission Results"}
                    />
                    <br/>
                    {(receipt.errors||[]).map((m, key) =>
                        <AlertBar key={key} label={m} severity={"error"} size={"small"} />
                    )}
                    {(receipt.warnings||[]).map((m, key) =>
                        <AlertBar key={key} label={m} severity={"warning"} size={"small"} />
                    )}
                    {content}
                </Box>
            </React.Fragment>
        );
    }

    return (
        <Main breadcrumbs={[{label: "Home", url: "/"}, homeBreadcrumb(year, organization, implementation), breadcrumb]} fullWidth={true} fullHeight={true}>
            <Box className={classes.content} textAlign={"center"}>
                <Paper className={classes.paper}>
                    {spinner}
                    {body}
                </Paper>
            </Box>
        </Main>
    );
};

Receipt.propTypes = {
    isValidURL: PropTypes.bool.isRequired,
    year: PropTypes.string,
    organization: PropTypes.object,
    implementation: PropTypes.object,
    npi: PropTypes.object,
    receipt: PropTypes.object,
    yearInfo: PropTypes.object,
    isLoading: PropTypes.bool,
    setView: PropTypes.func,
    onSubmit: PropTypes.func
};

const WrappedReceipt = (props) => {
    return (
        <HasDRCF year={props.year} orgKey={props.organization.key} implKey={props.implementation.key}>
            <Receipt {...props} />
        </HasDRCF>
    );
};

export default Container(
    WrappedReceipt,
    () => [
        UserStore,
        NPIStore,
        SubmissionReceiptStore
    ],
    (state, props) => {
        const params = props.match.params;
        const year = params.year;
        const orgKey = params.orgKey;
        const implKey = params.implKey;
        const npiId = params.npiId;
        const type = params.type;

        const org = UserStore.getOrganization(orgKey);
        const impl = UserStore.getImplementation(orgKey, implKey);
        const npi = NPIStore.getNPI(npiId);
        const receipt = SubmissionReceiptStore.getReceipt();
        const isValidURL = !!org && !!impl && !SubmissionReceiptStore.isError();
        const isSubmission = SubmissionReceiptStore.isSubmission();
        const isLoading = NPIStore.isLoading() || SubmissionReceiptStore.isLoading();
        return {
            ...props,
            isValidURL,
            year,
            organization: org || {},
            implementation: impl || {},
            npi: npi || {},
            receipt: receipt,
            yearInfo: NPIStore.getYear(),
            isLoading: isLoading,
            isSubmission: isSubmission,
            onSubmit: dispatchSubmit,
            submissionType: type
        };
    }
);
