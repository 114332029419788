import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InputBase from '@mui/material/InputBase';
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import { lighten, darken } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import OptionsIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import FilterIcon from '@mui/icons-material/FilterList';
import {
    getAppliedFiltersCount
} from "../../../../data/submit/selectors/measures";
import Container from "../../../../data/Container";
import MeasuresMetaStore from "../../../../data/submit/MeasuresMetaStore";
import MeasuresDraftStore from "../../../../data/submit/MeasuresDraftStore";
import moment from "moment-timezone";
import MeasuresScoreStore from "../../../../data/submit/MeasuresScoreStore";
import config from "../../../../../config";
import { FormattedMessage, InfoChip } from "@armus/armus-dashboard";

const useStyles = makeStyles((theme) => {
    const colorModifier = theme.type === "dark" ? darken : lighten;
    return {
        root: {
            backgroundColor: colorModifier(theme.palette.background.default, 0.25),
            flexGrow: 1,
            //marginTop: -theme.spacing(1),
            marginLeft: -theme.spacing(2),
            marginRight: -theme.spacing(2),
            top: 0,
            left: "auto",
            right: 0,
            position: "sticky",
            zIndex: 3
        },
        menuButton: {
            marginRight: theme.spacing(2)
        },
        title: {
            flexGrow: 1,
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block'
            }
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: colorModifier(theme.palette.background.default, 0.25),
            '&:hover': {
                backgroundColor: colorModifier(theme.palette.background.default, 0.5)
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
                width: 'auto'
            }
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        inputRoot: {
            color: 'inherit'
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 1),
            // vertical padding + font size from searchIcon
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '12ch',
                    '&:focus': {
                    width: '20ch'
                }
            }
        },
        filtersContainer: {
            maxHeight: "40vh",
            overflow: "auto",
            padding: theme.spacing(2),
            borderBottom: "1px solid " + darken(theme.palette.background.default, 0.25)
        },
        quickFilter: {
            marginLeft: 4,
            "&.MuiQuickButton-active": {
                backgroundColor: theme.palette["primary"].main,
                color: theme.palette["primary"].contrastText
            }
        },
        scoreChip: {
            minWidth: 50,
            fontWeight: "bold",
            fontSize: 14
        },
        toolbarSection: {
            marginLeft: theme.spacing(1)
        }
    };
});

const MeasureFilterBar = ({
    isReview,
    disabled,
    stickyRef,
    length,
    filteredLength,
    filters,
    onChange,
    measureSetProxy
}) => {
    const optionsRef = React.useRef();
    const [showFilters, setShowFilters] = React.useState(false);
    const [showSearchOptions, setShowSearchOptions] = React.useState(false);
    const [stickyOffsetHeight, setStickyOffsetHeight] = React.useState(0);
    const classes = useStyles();
    const appliedFilterCount = getAppliedFiltersCount(filters);
    const searchFilter = filters.search;
    const filtersArray = Object.keys(filters).filter(key => !["search", "onlySelected", "onlyPicked", "category"].includes(key));
    React.useEffect(() => {
        setStickyOffsetHeight(stickyRef.current ? stickyRef.current.clientHeight : 0);},
        [stickyRef]
    );
    const selectedMeasuresCount = measureSetProxy.getSelectedMeasuresCount() || 0;
    const pickedMeasuresCount = measureSetProxy.getPickedMeasuresCount() || 0;
    const measureSetData = measureSetProxy.getValue();

    return (
        <div className={classes.root} style={{top: stickyOffsetHeight}}>
            <AppBar
                color={"default"}
                position="sticky"
                elevation={1}
            >
                {isReview  ? (
                    <Toolbar>
                        <Typography className={classes.title} variant="body1" component={"div"} noWrap>
                            <Typography variant={"h5"} component={"h2"}>
                                {measureSetProxy.title()} &nbsp;
                                {measureSetProxy.isIncluded() ?
                                    <InfoChip size="xs" color={"success"} label={"Included"} variant={"outlined"} /> : <InfoChip size="xs" color={"default"} label={"Excluded"} disabled variant={"outlined"}/>
                                }
                            </Typography>
                            {selectedMeasuresCount} measures selected for submission.
                        </Typography>
                        {measureSetProxy.isPerformanceDateRequired() && <div className={classes.toolbarSection}>
                            Performance Period: <strong>{moment(measureSetData.performanceStart).utc().format(config.ui.dateFormat)}&nbsp;-&nbsp;{moment(measureSetData.performanceEnd).utc().format(config.ui.dateFormat)}</strong>
                        </div>}
                        {measureSetProxy.isCehrtIdRequired() && <div className={classes.toolbarSection}>
                            CEHRT ID: <strong>{measureSetData.cehrtId}</strong>
                        </div>}
                        <div className={classes.toolbarSection}>
                            Preview Score: <Chip color="primary" className={classes.scoreChip} label={
                                <React.Fragment>
                                    <strong>{measureSetProxy.getScore()}</strong> / {measureSetProxy.getMaxScore()}
                                </React.Fragment>
                            } />
                        </div>
                    </Toolbar>
                ) : (
                <Toolbar>
                    <Typography className={classes.title} variant="body1" component={"div"} noWrap>
                        Showing <strong>{filteredLength}</strong>{ filteredLength !== length ? " out of " + length : "" } measures.

                        {(!disabled && measureSetProxy.id !== "review") && (
                            <React.Fragment>
                                <Button
                                    className={classes.quickFilter + (filters.onlySelected.value ? " MuiQuickButton-active" : "")}
                                    variant={"text"}
                                    onClick={() => onChange(filters.onlySelected.key, null, !filters.onlySelected.value)}
                                >
                                    {filters.onlySelected.label} ({selectedMeasuresCount})
                                </Button>
                                <Button
                                    className={classes.quickFilter + (filters.onlyPicked.value ? " MuiQuickButton-active" : "")}
                                    variant={"text"}
                                    onClick={() => onChange(filters.onlyPicked.key, null, !filters.onlyPicked.value)}
                                >
                                    {filters.onlyPicked.label} ({pickedMeasuresCount})
                                </Button>
                            </React.Fragment>
                        )}
                        {(!disabled && !!filters.category) && Object.keys(filters.category.values).map((valueKey) => (
                            <Button
                                className={classes.quickFilter + (filters.category.values[valueKey].selected ? " MuiQuickButton-active" : "")}
                                key={valueKey}
                                variant={"text"}
                                onClick={() => onChange(filters.category.key, valueKey, null)}
                            >
                                {filters.category.values[valueKey].label} ({filters.category.values[valueKey].count})
                            </Button>
                        ))}
                    </Typography>
                    {(!disabled && length > 0) && <React.Fragment>
                        <IconButton
                            ref={optionsRef}
                            color="inherit"
                            onClick={() => setShowSearchOptions(!showSearchOptions)}
                        >
                            <OptionsIcon />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={optionsRef.current}
                            keepMounted
                            open={showSearchOptions}
                            onClose={() => setShowSearchOptions(false)}
                        >
                            {Object.keys(searchFilter.values).map((valueKey) => searchFilter.values[valueKey]).map(value => (
                                <MenuItem
                                    key={value.key}
                                    onClick={() => onChange(searchFilter.key, value.key, null)}
                                >
                                    <Checkbox
                                        checked={value.selected}
                                        color={"primary"}
                                    />
                                    {`Search ${value.label}`}
                                </MenuItem>
                            ))}
                        </Menu>
                        <div className={classes.search}>
                            <InputBase
                                placeholder={searchFilter.label}
                                value={searchFilter.value}
                                onChange={(e) => onChange(searchFilter.key, null, e.target.value)}
                                endAdornment={
                                    <SearchIcon />
                                }
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput
                                }}
                            />
                        </div>
                        {(!disabled && filtersArray.length > 0) && <Button color="inherit" onClick={() => setShowFilters(!showFilters)}>
                            <Badge badgeContent={appliedFilterCount} color="primary">
                                <FilterIcon />
                                &nbsp;
                                <FormattedMessage
                                    id="draft.filterBar.moreFiltersButton"
                                    defaultMessage="More Filters"
                                />
                            </Badge>
                        </Button>}
                    </React.Fragment>}
                </Toolbar>
                )}
            </AppBar>
            <Collapse in={showFilters}>
                <Grid container className={classes.filtersContainer}>
                    {filtersArray.map(filterKey => {
                        const filter = filters[filterKey];
                        const values = Object.keys(filter.values).map((valueKey) => filter.values[valueKey]);
                        values.sort((a, b) => (a.key || "").localeCompare((b.key || ""), navigator.languages[0] || navigator.language, {
                            numeric: true,
                            ignorePunctuation: true
                        }));
                        return (
                            <Grid item xs={Math.max(3, 12/filtersArray.length)} key={filter.key}>
                                <div><strong>{filter.label}</strong></div>
                                {values.map(value => (
                                    <div key={value.key}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={value.selected}
                                                    onChange={() => onChange(filter.key, value.key, null)}
                                                    name="checkedB"
                                                    size={"small"}
                                                    color="primary"
                                                />
                                            }
                                            label={`${value.label} ${value.count !== null ? `(${value.count})` : ``}`}
                                        />
                                    </div>
                                ))}
                            </Grid>
                        );
                    })}
                </Grid>
            </Collapse>
        </div>

    );
};

export default MeasureFilterBar;

Container(
    MeasureFilterBar,
    () => [
        MeasuresMetaStore,
        MeasuresDraftStore,
        MeasuresScoreStore
    ],
    (state, props) => props,
    {pure: false}
);
